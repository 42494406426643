import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type ChangeForgotPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type CreateImageInput = {
  contentType: Scalars['String'];
  key: Scalars['String'];
};

export type CreateLogInput = {
  currentAddress: Scalars['String'];
  inputDate: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  permitId: Scalars['String'];
  receivingUserId: Scalars['String'];
};

export type CreateManualLogInput = {
  caliber: Scalars['String'];
  corporateJournalNo?: InputMaybe<Scalars['String']>;
  corporatePermitImageType: Scalars['String'];
  corporatePermitImageUrl: Scalars['String'];
  corporatePermitValidFrom?: InputMaybe<Scalars['DateTime']>;
  corporatePermitValidTo?: InputMaybe<Scalars['DateTime']>;
  currentAddress: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  inputDate: Scalars['DateTime'];
  journalNo: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  logType: ManualLogType;
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  receivingUserId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  weaponNo: Scalars['String'];
  weaponPart?: InputMaybe<Scalars['String']>;
  weaponType: Scalars['String'];
};

export type CreatePermitInput = {
  caliber: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  journalNo: Scalars['String'];
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  permitUser: PermitUser;
  storageAddress: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  weaponNo: Scalars['String'];
  weaponType: Scalars['String'];
};

export type CreateSubLogInput = {
  currentAddress: Scalars['String'];
  inputDate: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  receivingUserId: Scalars['String'];
  rootLogId?: InputMaybe<Scalars['String']>;
};

export type CreateSubPermitInput = {
  caliber: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  journalNo: Scalars['String'];
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  permitUser: PermitUser;
  rootPermitId?: InputMaybe<Scalars['String']>;
  storageAddress: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  weaponNo: Scalars['String'];
  weaponPart?: InputMaybe<Scalars['String']>;
  weaponType: Scalars['String'];
};

export type EditNoteInput = {
  logId: Scalars['String'];
  note: Scalars['String'];
};

export type EditPermitInput = {
  caliber?: InputMaybe<Scalars['String']>;
  imageType?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  isHome?: InputMaybe<Scalars['Boolean']>;
  journalNo?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  permitId: Scalars['String'];
  storageAddress?: InputMaybe<Scalars['String']>;
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
  weaponNo?: InputMaybe<Scalars['String']>;
  weaponPart?: InputMaybe<Scalars['String']>;
  weaponType?: InputMaybe<Scalars['String']>;
};

export type EditSubPermitInput = {
  caliber?: InputMaybe<Scalars['String']>;
  imageType?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  isHome?: InputMaybe<Scalars['Boolean']>;
  journalNo?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  permitId: Scalars['String'];
  rootPermitId: Scalars['String'];
  storageAddress?: InputMaybe<Scalars['String']>;
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
  weaponNo?: InputMaybe<Scalars['String']>;
  weaponPart?: InputMaybe<Scalars['String']>;
  weaponType?: InputMaybe<Scalars['String']>;
};

export type EndLogInput = {
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endLogType: EndLogType;
  firstName?: InputMaybe<Scalars['String']>;
  imageType?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  inputDate: Scalars['DateTime'];
  journalNo?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  rootLogId: Scalars['String'];
  userId: Scalars['String'];
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
};

/** The type of the user who ends the log */
export enum EndLogType {
  Owner = 'OWNER',
  ThirdParty = 'THIRD_PARTY'
}

export type GetImageInput = {
  key: Scalars['String'];
};

export type GetMyLogsInput = {
  logType: MyLogType;
};

export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
};

export type Log = {
  __typename?: 'Log';
  _id: Scalars['String'];
  currentAddress: Scalars['String'];
  givingUser: User;
  inputDate: Scalars['DateTime'];
  isEnded?: Maybe<Scalars['Boolean']>;
  isSubLog?: Maybe<Scalars['Boolean']>;
  note: Scalars['String'];
  permit: Permit;
  receivingUser: User;
  rootLog?: Maybe<Log>;
  serialNo?: Maybe<Scalars['Float']>;
  subLogs?: Maybe<Array<Log>>;
};

/** Types of manual logs */
export enum ManualLogType {
  Business = 'BUSINESS',
  Standard = 'STANDARD'
}

export type Mutation = {
  __typename?: 'Mutation';
  changeForgotPassword?: Maybe<User>;
  changeMail?: Maybe<User>;
  changePassword?: Maybe<User>;
  createLog: Log;
  createManualLog: Log;
  createPermit: Permit;
  createSubLog: Log;
  createSubPermit: Permit;
  deletePermit: Permit;
  editAndUpdatePermit: Permit;
  editAndUpdateSubPermit: Permit;
  editNote: Log;
  editPermit: Permit;
  endLog: Log;
  login?: Maybe<User>;
  logout: Scalars['Boolean'];
  register: User;
  registerAdmin: User;
  updateUser: User;
};


export type MutationChangeForgotPasswordArgs = {
  input: ChangeForgotPasswordInput;
};


export type MutationChangeMailArgs = {
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCreateLogArgs = {
  input: CreateLogInput;
};


export type MutationCreateManualLogArgs = {
  input: CreateManualLogInput;
};


export type MutationCreatePermitArgs = {
  input: CreatePermitInput;
};


export type MutationCreateSubLogArgs = {
  input: CreateSubLogInput;
};


export type MutationCreateSubPermitArgs = {
  input: CreateSubPermitInput;
};


export type MutationDeletePermitArgs = {
  permitId: Scalars['String'];
};


export type MutationEditAndUpdatePermitArgs = {
  input: EditPermitInput;
};


export type MutationEditAndUpdateSubPermitArgs = {
  input: EditSubPermitInput;
};


export type MutationEditNoteArgs = {
  input: EditNoteInput;
};


export type MutationEditPermitArgs = {
  input: EditPermitInput;
};


export type MutationEndLogArgs = {
  input: EndLogInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRegisterAdminArgs = {
  input: RegisterInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The type of log to fetch with getMyLogsV2 query */
export enum MyLogType {
  Active = 'ACTIVE',
  All = 'ALL',
  Ended = 'ENDED'
}

export type Permit = {
  __typename?: 'Permit';
  _id: Scalars['String'];
  caliber: Scalars['String'];
  imageType?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHome?: Maybe<Scalars['Boolean']>;
  isSubPermit?: Maybe<Scalars['Boolean']>;
  journalNo: Scalars['String'];
  logs?: Maybe<Array<Log>>;
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  owner?: Maybe<User>;
  rootPermit?: Maybe<Permit>;
  storageAddress: Scalars['String'];
  subPermits?: Maybe<Array<Permit>>;
  user?: Maybe<User>;
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  versions: Array<Permit>;
  weaponNo: Scalars['String'];
  weaponPart?: Maybe<Scalars['String']>;
  weaponType: Scalars['String'];
};

/** Owner or user of the permit */
export enum PermitUser {
  Owner = 'OWNER',
  User = 'USER'
}

export type Query = {
  __typename?: 'Query';
  confirmChangeMail?: Maybe<User>;
  confirmUser?: Maybe<User>;
  forgotPassword: Scalars['Boolean'];
  getCorporateClients?: Maybe<Array<User>>;
  getImageUploadUrl: Image;
  getImageUrl: Image;
  getLogById: Log;
  getMyLogsV2: Array<Log>;
  getPermitById: Permit;
  getPermits: Array<Permit>;
  getPermitsByWeaponNo: Array<Permit>;
  me?: Maybe<User>;
};


export type QueryConfirmChangeMailArgs = {
  token: Scalars['String'];
};


export type QueryConfirmUserArgs = {
  token: Scalars['String'];
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String'];
};


export type QueryGetImageUploadUrlArgs = {
  input: CreateImageInput;
};


export type QueryGetImageUrlArgs = {
  input: GetImageInput;
};


export type QueryGetLogByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetMyLogsV2Args = {
  input: GetMyLogsInput;
};


export type QueryGetPermitByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPermitsByWeaponNoArgs = {
  weaponNo: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  confirmed: Scalars['Boolean'];
  corporateJournalNo?: Maybe<Scalars['String']>;
  corporateName?: Maybe<Scalars['String']>;
  corporatePermitImageType?: Maybe<Scalars['String']>;
  corporatePermitImageUrl?: Maybe<Scalars['String']>;
  corporatePermitValidFrom?: Maybe<Scalars['DateTime']>;
  corporatePermitValidTo?: Maybe<Scalars['DateTime']>;
  currentSerialNo?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  lastName: Scalars['String'];
  userType: UserType;
};

/** Types of users */
export enum UserType {
  Corporate = 'CORPORATE',
  CorporateClient = 'CORPORATE_CLIENT',
  GuestUser = 'GUEST_USER',
  Private = 'PRIVATE'
}

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type EditNoteMutationVariables = Exact<{
  input: EditNoteInput;
}>;


export type EditNoteMutation = { __typename?: 'Mutation', editNote: { __typename?: 'Log', _id: string } };

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'User', fullName: string } | null };

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'User', firstName: string, lastName: string, fullName: string, email: string } };

export type RegisterAdminMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterAdminMutation = { __typename?: 'Mutation', registerAdmin: { __typename?: 'User', firstName: string, lastName: string, fullName: string, email: string } };

export type CreateManualLogMutationVariables = Exact<{
  input: CreateManualLogInput;
}>;


export type CreateManualLogMutation = { __typename?: 'Mutation', createManualLog: { __typename?: 'Log', _id: string } };

export type CorporateClientFragment = { __typename?: 'User', _id: string, corporateName?: string | null, firstName: string, lastName: string, corporateJournalNo?: string | null, email: string, corporatePermitImageUrl?: string | null, corporatePermitImageType?: string | null, corporatePermitValidFrom?: any | null, corporatePermitValidTo?: any | null, address?: string | null };

export type GetCorporateClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCorporateClientsQuery = { __typename?: 'Query', getCorporateClients?: Array<{ __typename?: 'User', _id: string, corporateName?: string | null, firstName: string, lastName: string, corporateJournalNo?: string | null, email: string, corporatePermitImageUrl?: string | null, corporatePermitImageType?: string | null, corporatePermitValidFrom?: any | null, corporatePermitValidTo?: any | null, address?: string | null }> | null };

export type CreateLogMutationVariables = Exact<{
  input: CreateLogInput;
}>;


export type CreateLogMutation = { __typename?: 'Mutation', createLog: { __typename?: 'Log', _id: string } };

export type PermitByWeaponNoFragment = { __typename?: 'Permit', _id: string, imageUrl?: string | null, imageType?: string | null, weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, storageAddress: string, validFrom: any, validTo: any, journalNo: string, weaponPart?: string | null, owner?: { __typename?: 'User', _id: string } | null, user?: { __typename?: 'User', _id: string } | null };

export type GetPermitsByWeaponNoQueryVariables = Exact<{
  weaponNo: Scalars['String'];
}>;


export type GetPermitsByWeaponNoQuery = { __typename?: 'Query', getPermitsByWeaponNo: Array<{ __typename?: 'Permit', _id: string, imageUrl?: string | null, imageType?: string | null, weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, storageAddress: string, validFrom: any, validTo: any, journalNo: string, weaponPart?: string | null, owner?: { __typename?: 'User', _id: string } | null, user?: { __typename?: 'User', _id: string } | null }> };

export type CreatePermitMutationVariables = Exact<{
  input: CreatePermitInput;
}>;


export type CreatePermitMutation = { __typename?: 'Mutation', createPermit: { __typename?: 'Permit', _id: string, user?: { __typename?: 'User', _id: string } | null } };

export type GetImageUploadUrlQueryVariables = Exact<{
  input: CreateImageInput;
}>;


export type GetImageUploadUrlQuery = { __typename?: 'Query', getImageUploadUrl: { __typename?: 'Image', url: string } };

export type CreateSubPermitMutationVariables = Exact<{
  input: CreateSubPermitInput;
}>;


export type CreateSubPermitMutation = { __typename?: 'Mutation', createSubPermit: { __typename?: 'Permit', _id: string } };

export type EditAndUpdatePermitMutationVariables = Exact<{
  input: EditPermitInput;
}>;


export type EditAndUpdatePermitMutation = { __typename?: 'Mutation', editAndUpdatePermit: { __typename?: 'Permit', _id: string, images?: Array<string> | null } };

export type EditPermitMutationVariables = Exact<{
  input: EditPermitInput;
}>;


export type EditPermitMutation = { __typename?: 'Mutation', editPermit: { __typename?: 'Permit', _id: string } };

export type GetPermitByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPermitByIdQuery = { __typename?: 'Query', getPermitById: { __typename?: 'Permit', _id: string, imageUrl?: string | null, imageType?: string | null, weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, storageAddress: string, validFrom: any, validTo: any, journalNo: string, weaponPart?: string | null, images?: Array<string> | null, owner?: { __typename?: 'User', _id: string } | null, user?: { __typename?: 'User', _id: string } | null } };

export type EditAndUpdateSubPermitMutationVariables = Exact<{
  input: EditSubPermitInput;
}>;


export type EditAndUpdateSubPermitMutation = { __typename?: 'Mutation', editAndUpdateSubPermit: { __typename?: 'Permit', _id: string } };

export type EndLogMutationVariables = Exact<{
  input: EndLogInput;
}>;


export type EndLogMutation = { __typename?: 'Mutation', endLog: { __typename?: 'Log', _id: string } };

export type CreateSubLogMutationVariables = Exact<{
  input: CreateSubLogInput;
}>;


export type CreateSubLogMutation = { __typename?: 'Mutation', createSubLog: { __typename?: 'Log', _id: string } };

export type PrintLogPermitFragment = { __typename?: 'Permit', weaponType: string, manufacturer: string, caliber: string, weaponNo: string, journalNo: string, model: string };

export type BasePrintLogFragment = { __typename?: 'Log', _id: string, inputDate: any, note: string, serialNo?: number | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', weaponType: string, manufacturer: string, caliber: string, weaponNo: string, journalNo: string, model: string } };

export type PrintLogFragment = { __typename?: 'Log', _id: string, inputDate: any, note: string, serialNo?: number | null, subLogs?: Array<{ __typename?: 'Log', _id: string, inputDate: any, note: string, serialNo?: number | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', weaponType: string, manufacturer: string, caliber: string, weaponNo: string, journalNo: string, model: string } }> | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', weaponType: string, manufacturer: string, caliber: string, weaponNo: string, journalNo: string, model: string } };

export type GetMyLogsPrintQueryVariables = Exact<{
  input: GetMyLogsInput;
}>;


export type GetMyLogsPrintQuery = { __typename?: 'Query', getMyLogsV2: Array<{ __typename?: 'Log', _id: string, inputDate: any, note: string, serialNo?: number | null, subLogs?: Array<{ __typename?: 'Log', _id: string, inputDate: any, note: string, serialNo?: number | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', weaponType: string, manufacturer: string, caliber: string, weaponNo: string, journalNo: string, model: string } }> | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', weaponType: string, manufacturer: string, caliber: string, weaponNo: string, journalNo: string, model: string } }> };

export type LogDetailsFragment = { __typename?: 'Log', _id: string, isEnded?: boolean | null, isSubLog?: boolean | null, inputDate: any, note: string, currentAddress: string, receivingUser: { __typename?: 'User', _id: string, fullName: string }, givingUser: { __typename?: 'User', _id: string, fullName: string }, permit: { __typename?: 'Permit', _id: string, imageUrl?: string | null, imageType?: string | null, weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, weaponPart?: string | null, validFrom: any, validTo: any, journalNo: string, isHome?: boolean | null } };

export type GetLogDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLogDetailsQuery = { __typename?: 'Query', getLogById: { __typename?: 'Log', _id: string, isEnded?: boolean | null, isSubLog?: boolean | null, inputDate: any, note: string, currentAddress: string, receivingUser: { __typename?: 'User', _id: string, fullName: string }, givingUser: { __typename?: 'User', _id: string, fullName: string }, permit: { __typename?: 'Permit', _id: string, imageUrl?: string | null, imageType?: string | null, weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, weaponPart?: string | null, validFrom: any, validTo: any, journalNo: string, isHome?: boolean | null } } };

export type DeletePermitMutationVariables = Exact<{
  permitId: Scalars['String'];
}>;


export type DeletePermitMutation = { __typename?: 'Mutation', deletePermit: { __typename?: 'Permit', isDeleted?: boolean | null } };

export type PermitDetailsFragment = { __typename?: 'Permit', weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, storageAddress: string, validFrom: any, validTo: any, journalNo: string, weaponPart?: string | null, isHome?: boolean | null, imageUrl?: string | null, imageType?: string | null, logs?: Array<{ __typename?: 'Log', _id: string, isEnded?: boolean | null, inputDate: any, currentAddress: string, receivingUser: { __typename?: 'User', _id: string, fullName: string } }> | null };

export type GetPermitDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPermitDetailsQuery = { __typename?: 'Query', getPermitById: { __typename?: 'Permit', weaponType: string, weaponNo: string, manufacturer: string, model: string, caliber: string, storageAddress: string, validFrom: any, validTo: any, journalNo: string, weaponPart?: string | null, isHome?: boolean | null, imageUrl?: string | null, imageType?: string | null, logs?: Array<{ __typename?: 'Log', _id: string, isEnded?: boolean | null, inputDate: any, currentAddress: string, receivingUser: { __typename?: 'User', _id: string, fullName: string } }> | null } };

export type GetSignedUrlForGetImageQueryVariables = Exact<{
  input: GetImageInput;
}>;


export type GetSignedUrlForGetImageQuery = { __typename?: 'Query', getImageUrl: { __typename?: 'Image', url: string } };

export type ChangeForgotPasswordMutationVariables = Exact<{
  input: ChangeForgotPasswordInput;
}>;


export type ChangeForgotPasswordMutation = { __typename?: 'Mutation', changeForgotPassword?: { __typename?: 'User', _id: string } | null };

export type ConfirmUserQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmUserQuery = { __typename?: 'Query', confirmUser?: { __typename?: 'User', fullName: string, userType: UserType } | null };

export type ForgotPasswordQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordQuery = { __typename?: 'Query', forgotPassword: boolean };

export type PermitRowFragment = { __typename?: 'Permit', _id: string, weaponType: string, caliber: string, isSubPermit?: boolean | null, isHome?: boolean | null, weaponNo: string, manufacturer: string, model: string, user?: { __typename?: 'User', _id: string } | null, owner?: { __typename?: 'User', _id: string } | null, subPermits?: Array<{ __typename?: 'Permit', _id: string, weaponType: string, caliber: string, isSubPermit?: boolean | null, weaponPart?: string | null, isHome?: boolean | null, weaponNo: string, manufacturer: string, model: string, user?: { __typename?: 'User', _id: string } | null, owner?: { __typename?: 'User', _id: string } | null }> | null };

export type GetPermitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermitsQuery = { __typename?: 'Query', getPermits: Array<{ __typename?: 'Permit', _id: string, weaponType: string, caliber: string, isSubPermit?: boolean | null, isHome?: boolean | null, weaponNo: string, manufacturer: string, model: string, user?: { __typename?: 'User', _id: string } | null, owner?: { __typename?: 'User', _id: string } | null, subPermits?: Array<{ __typename?: 'Permit', _id: string, weaponType: string, caliber: string, isSubPermit?: boolean | null, weaponPart?: string | null, isHome?: boolean | null, weaponNo: string, manufacturer: string, model: string, user?: { __typename?: 'User', _id: string } | null, owner?: { __typename?: 'User', _id: string } | null }> | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, fullName: string, userType: UserType, admin?: boolean | null, confirmed: boolean, address?: string | null } | null };

export type UpdateAddressMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', _id: string, address?: string | null } };

export type ChangeMailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ChangeMailMutation = { __typename?: 'Mutation', changeMail?: { __typename?: 'User', _id: string, email: string } | null };

export type ConfirmChangeMailQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmChangeMailQuery = { __typename?: 'Query', confirmChangeMail?: { __typename?: 'User', _id: string, email: string } | null };

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: { __typename?: 'User', _id: string } | null };

export type LogRowPermitFragment = { __typename?: 'Permit', journalNo: string, weaponType: string, manufacturer: string, caliber: string, model: string, weaponNo: string, owner?: { __typename?: 'User', fullName: string } | null, user?: { __typename?: 'User', fullName: string } | null };

export type BaseRowLogFragment = { __typename?: 'Log', _id: string, inputDate: any, note: string, currentAddress: string, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', journalNo: string, weaponType: string, manufacturer: string, caliber: string, model: string, weaponNo: string, owner?: { __typename?: 'User', fullName: string } | null, user?: { __typename?: 'User', fullName: string } | null } };

export type LogRowFragment = { __typename?: 'Log', serialNo?: number | null, isSubLog?: boolean | null, _id: string, inputDate: any, note: string, currentAddress: string, subLogs?: Array<{ __typename?: 'Log', _id: string, inputDate: any, note: string, currentAddress: string, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', journalNo: string, weaponType: string, manufacturer: string, caliber: string, model: string, weaponNo: string, owner?: { __typename?: 'User', fullName: string } | null, user?: { __typename?: 'User', fullName: string } | null } }> | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', journalNo: string, weaponType: string, manufacturer: string, caliber: string, model: string, weaponNo: string, owner?: { __typename?: 'User', fullName: string } | null, user?: { __typename?: 'User', fullName: string } | null } };

export type GetMyLogsV2QueryVariables = Exact<{
  input: GetMyLogsInput;
}>;


export type GetMyLogsV2Query = { __typename?: 'Query', getMyLogsV2: Array<{ __typename?: 'Log', serialNo?: number | null, isSubLog?: boolean | null, _id: string, inputDate: any, note: string, currentAddress: string, subLogs?: Array<{ __typename?: 'Log', _id: string, inputDate: any, note: string, currentAddress: string, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', journalNo: string, weaponType: string, manufacturer: string, caliber: string, model: string, weaponNo: string, owner?: { __typename?: 'User', fullName: string } | null, user?: { __typename?: 'User', fullName: string } | null } }> | null, givingUser: { __typename?: 'User', fullName: string }, receivingUser: { __typename?: 'User', fullName: string }, permit: { __typename?: 'Permit', journalNo: string, weaponType: string, manufacturer: string, caliber: string, model: string, weaponNo: string, owner?: { __typename?: 'User', fullName: string } | null, user?: { __typename?: 'User', fullName: string } | null } }> };

export const CorporateClientFragmentDoc = gql`
    fragment CorporateClient on User {
  _id
  corporateName
  firstName
  lastName
  corporateJournalNo
  email
  corporatePermitImageUrl
  corporatePermitImageType
  corporatePermitValidFrom
  corporatePermitValidTo
  address
}
    `;
export const PermitByWeaponNoFragmentDoc = gql`
    fragment PermitByWeaponNo on Permit {
  _id
  imageUrl
  imageType
  weaponType
  weaponNo
  manufacturer
  model
  caliber
  storageAddress
  validFrom
  validTo
  journalNo
  weaponPart
  owner {
    _id
  }
  user {
    _id
  }
}
    `;
export const PrintLogPermitFragmentDoc = gql`
    fragment PrintLogPermit on Permit {
  weaponType
  manufacturer
  caliber
  weaponNo
  journalNo
  model
}
    `;
export const BasePrintLogFragmentDoc = gql`
    fragment BasePrintLog on Log {
  _id
  inputDate
  note
  serialNo
  givingUser {
    fullName
  }
  receivingUser {
    fullName
  }
  permit {
    ...PrintLogPermit
  }
}
    ${PrintLogPermitFragmentDoc}`;
export const PrintLogFragmentDoc = gql`
    fragment PrintLog on Log {
  ...BasePrintLog
  subLogs {
    ...BasePrintLog
  }
}
    ${BasePrintLogFragmentDoc}`;
export const LogDetailsFragmentDoc = gql`
    fragment LogDetails on Log {
  _id
  receivingUser {
    _id
    fullName
  }
  givingUser {
    _id
    fullName
  }
  isEnded
  isSubLog
  permit {
    _id
    imageUrl
    imageType
    weaponType
    weaponNo
    manufacturer
    model
    caliber
    weaponPart
    validFrom
    validTo
    journalNo
    isHome
  }
  inputDate
  note
  currentAddress
}
    `;
export const PermitDetailsFragmentDoc = gql`
    fragment PermitDetails on Permit {
  weaponType
  weaponNo
  manufacturer
  model
  caliber
  storageAddress
  validFrom
  validTo
  journalNo
  weaponPart
  isHome
  imageUrl
  imageType
  logs {
    _id
    isEnded
    inputDate
    currentAddress
    receivingUser {
      _id
      fullName
    }
  }
}
    `;
export const PermitRowFragmentDoc = gql`
    fragment PermitRow on Permit {
  _id
  weaponType
  caliber
  isSubPermit
  isHome
  weaponNo
  manufacturer
  model
  user {
    _id
  }
  owner {
    _id
  }
  isHome
  subPermits {
    _id
    weaponType
    caliber
    isSubPermit
    weaponPart
    isHome
    weaponNo
    manufacturer
    model
    user {
      _id
    }
    owner {
      _id
    }
  }
}
    `;
export const LogRowPermitFragmentDoc = gql`
    fragment LogRowPermit on Permit {
  journalNo
  weaponType
  manufacturer
  caliber
  model
  weaponNo
  owner {
    fullName
  }
  user {
    fullName
  }
}
    `;
export const BaseRowLogFragmentDoc = gql`
    fragment BaseRowLog on Log {
  _id
  inputDate
  note
  currentAddress
  givingUser {
    fullName
  }
  receivingUser {
    fullName
  }
  permit {
    ...LogRowPermit
  }
}
    ${LogRowPermitFragmentDoc}`;
export const LogRowFragmentDoc = gql`
    fragment LogRow on Log {
  serialNo
  isSubLog
  ...BaseRowLog
  subLogs {
    ...BaseRowLog
  }
}
    ${BaseRowLogFragmentDoc}`;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EditNoteDocument = gql`
    mutation editNote($input: EditNoteInput!) {
  editNote(input: $input) {
    _id
  }
}
    `;
export type EditNoteMutationFn = Apollo.MutationFunction<EditNoteMutation, EditNoteMutationVariables>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteMutation(baseOptions?: Apollo.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, options);
      }
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = Apollo.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = Apollo.BaseMutationOptions<EditNoteMutation, EditNoteMutationVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $email: String!) {
  login(password: $password, email: $email) {
    fullName
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    firstName
    lastName
    fullName
    email
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RegisterAdminDocument = gql`
    mutation registerAdmin($input: RegisterInput!) {
  registerAdmin(input: $input) {
    firstName
    lastName
    fullName
    email
  }
}
    `;
export type RegisterAdminMutationFn = Apollo.MutationFunction<RegisterAdminMutation, RegisterAdminMutationVariables>;

/**
 * __useRegisterAdminMutation__
 *
 * To run a mutation, you first call `useRegisterAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAdminMutation, { data, loading, error }] = useRegisterAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAdminMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAdminMutation, RegisterAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAdminMutation, RegisterAdminMutationVariables>(RegisterAdminDocument, options);
      }
export type RegisterAdminMutationHookResult = ReturnType<typeof useRegisterAdminMutation>;
export type RegisterAdminMutationResult = Apollo.MutationResult<RegisterAdminMutation>;
export type RegisterAdminMutationOptions = Apollo.BaseMutationOptions<RegisterAdminMutation, RegisterAdminMutationVariables>;
export const CreateManualLogDocument = gql`
    mutation createManualLog($input: CreateManualLogInput!) {
  createManualLog(input: $input) {
    _id
  }
}
    `;
export type CreateManualLogMutationFn = Apollo.MutationFunction<CreateManualLogMutation, CreateManualLogMutationVariables>;

/**
 * __useCreateManualLogMutation__
 *
 * To run a mutation, you first call `useCreateManualLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualLogMutation, { data, loading, error }] = useCreateManualLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualLogMutation, CreateManualLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualLogMutation, CreateManualLogMutationVariables>(CreateManualLogDocument, options);
      }
export type CreateManualLogMutationHookResult = ReturnType<typeof useCreateManualLogMutation>;
export type CreateManualLogMutationResult = Apollo.MutationResult<CreateManualLogMutation>;
export type CreateManualLogMutationOptions = Apollo.BaseMutationOptions<CreateManualLogMutation, CreateManualLogMutationVariables>;
export const GetCorporateClientsDocument = gql`
    query getCorporateClients {
  getCorporateClients {
    ...CorporateClient
  }
}
    ${CorporateClientFragmentDoc}`;

/**
 * __useGetCorporateClientsQuery__
 *
 * To run a query within a React component, call `useGetCorporateClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorporateClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorporateClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCorporateClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetCorporateClientsQuery, GetCorporateClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorporateClientsQuery, GetCorporateClientsQueryVariables>(GetCorporateClientsDocument, options);
      }
export function useGetCorporateClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCorporateClientsQuery, GetCorporateClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorporateClientsQuery, GetCorporateClientsQueryVariables>(GetCorporateClientsDocument, options);
        }
export type GetCorporateClientsQueryHookResult = ReturnType<typeof useGetCorporateClientsQuery>;
export type GetCorporateClientsLazyQueryHookResult = ReturnType<typeof useGetCorporateClientsLazyQuery>;
export type GetCorporateClientsQueryResult = Apollo.QueryResult<GetCorporateClientsQuery, GetCorporateClientsQueryVariables>;
export const CreateLogDocument = gql`
    mutation createLog($input: CreateLogInput!) {
  createLog(input: $input) {
    _id
  }
}
    `;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, options);
      }
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const GetPermitsByWeaponNoDocument = gql`
    query getPermitsByWeaponNo($weaponNo: String!) {
  getPermitsByWeaponNo(weaponNo: $weaponNo) {
    ...PermitByWeaponNo
  }
}
    ${PermitByWeaponNoFragmentDoc}`;

/**
 * __useGetPermitsByWeaponNoQuery__
 *
 * To run a query within a React component, call `useGetPermitsByWeaponNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermitsByWeaponNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermitsByWeaponNoQuery({
 *   variables: {
 *      weaponNo: // value for 'weaponNo'
 *   },
 * });
 */
export function useGetPermitsByWeaponNoQuery(baseOptions: Apollo.QueryHookOptions<GetPermitsByWeaponNoQuery, GetPermitsByWeaponNoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermitsByWeaponNoQuery, GetPermitsByWeaponNoQueryVariables>(GetPermitsByWeaponNoDocument, options);
      }
export function useGetPermitsByWeaponNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermitsByWeaponNoQuery, GetPermitsByWeaponNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermitsByWeaponNoQuery, GetPermitsByWeaponNoQueryVariables>(GetPermitsByWeaponNoDocument, options);
        }
export type GetPermitsByWeaponNoQueryHookResult = ReturnType<typeof useGetPermitsByWeaponNoQuery>;
export type GetPermitsByWeaponNoLazyQueryHookResult = ReturnType<typeof useGetPermitsByWeaponNoLazyQuery>;
export type GetPermitsByWeaponNoQueryResult = Apollo.QueryResult<GetPermitsByWeaponNoQuery, GetPermitsByWeaponNoQueryVariables>;
export const CreatePermitDocument = gql`
    mutation createPermit($input: CreatePermitInput!) {
  createPermit(input: $input) {
    _id
    user {
      _id
    }
  }
}
    `;
export type CreatePermitMutationFn = Apollo.MutationFunction<CreatePermitMutation, CreatePermitMutationVariables>;

/**
 * __useCreatePermitMutation__
 *
 * To run a mutation, you first call `useCreatePermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermitMutation, { data, loading, error }] = useCreatePermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePermitMutation(baseOptions?: Apollo.MutationHookOptions<CreatePermitMutation, CreatePermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePermitMutation, CreatePermitMutationVariables>(CreatePermitDocument, options);
      }
export type CreatePermitMutationHookResult = ReturnType<typeof useCreatePermitMutation>;
export type CreatePermitMutationResult = Apollo.MutationResult<CreatePermitMutation>;
export type CreatePermitMutationOptions = Apollo.BaseMutationOptions<CreatePermitMutation, CreatePermitMutationVariables>;
export const GetImageUploadUrlDocument = gql`
    query getImageUploadUrl($input: CreateImageInput!) {
  getImageUploadUrl(input: $input) {
    url
  }
}
    `;

/**
 * __useGetImageUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetImageUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageUploadUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetImageUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>(GetImageUploadUrlDocument, options);
      }
export function useGetImageUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>(GetImageUploadUrlDocument, options);
        }
export type GetImageUploadUrlQueryHookResult = ReturnType<typeof useGetImageUploadUrlQuery>;
export type GetImageUploadUrlLazyQueryHookResult = ReturnType<typeof useGetImageUploadUrlLazyQuery>;
export type GetImageUploadUrlQueryResult = Apollo.QueryResult<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>;
export const CreateSubPermitDocument = gql`
    mutation createSubPermit($input: CreateSubPermitInput!) {
  createSubPermit(input: $input) {
    _id
  }
}
    `;
export type CreateSubPermitMutationFn = Apollo.MutationFunction<CreateSubPermitMutation, CreateSubPermitMutationVariables>;

/**
 * __useCreateSubPermitMutation__
 *
 * To run a mutation, you first call `useCreateSubPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubPermitMutation, { data, loading, error }] = useCreateSubPermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubPermitMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubPermitMutation, CreateSubPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubPermitMutation, CreateSubPermitMutationVariables>(CreateSubPermitDocument, options);
      }
export type CreateSubPermitMutationHookResult = ReturnType<typeof useCreateSubPermitMutation>;
export type CreateSubPermitMutationResult = Apollo.MutationResult<CreateSubPermitMutation>;
export type CreateSubPermitMutationOptions = Apollo.BaseMutationOptions<CreateSubPermitMutation, CreateSubPermitMutationVariables>;
export const EditAndUpdatePermitDocument = gql`
    mutation editAndUpdatePermit($input: EditPermitInput!) {
  editAndUpdatePermit(input: $input) {
    _id
    images
  }
}
    `;
export type EditAndUpdatePermitMutationFn = Apollo.MutationFunction<EditAndUpdatePermitMutation, EditAndUpdatePermitMutationVariables>;

/**
 * __useEditAndUpdatePermitMutation__
 *
 * To run a mutation, you first call `useEditAndUpdatePermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAndUpdatePermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAndUpdatePermitMutation, { data, loading, error }] = useEditAndUpdatePermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAndUpdatePermitMutation(baseOptions?: Apollo.MutationHookOptions<EditAndUpdatePermitMutation, EditAndUpdatePermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAndUpdatePermitMutation, EditAndUpdatePermitMutationVariables>(EditAndUpdatePermitDocument, options);
      }
export type EditAndUpdatePermitMutationHookResult = ReturnType<typeof useEditAndUpdatePermitMutation>;
export type EditAndUpdatePermitMutationResult = Apollo.MutationResult<EditAndUpdatePermitMutation>;
export type EditAndUpdatePermitMutationOptions = Apollo.BaseMutationOptions<EditAndUpdatePermitMutation, EditAndUpdatePermitMutationVariables>;
export const EditPermitDocument = gql`
    mutation editPermit($input: EditPermitInput!) {
  editPermit(input: $input) {
    _id
  }
}
    `;
export type EditPermitMutationFn = Apollo.MutationFunction<EditPermitMutation, EditPermitMutationVariables>;

/**
 * __useEditPermitMutation__
 *
 * To run a mutation, you first call `useEditPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPermitMutation, { data, loading, error }] = useEditPermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPermitMutation(baseOptions?: Apollo.MutationHookOptions<EditPermitMutation, EditPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPermitMutation, EditPermitMutationVariables>(EditPermitDocument, options);
      }
export type EditPermitMutationHookResult = ReturnType<typeof useEditPermitMutation>;
export type EditPermitMutationResult = Apollo.MutationResult<EditPermitMutation>;
export type EditPermitMutationOptions = Apollo.BaseMutationOptions<EditPermitMutation, EditPermitMutationVariables>;
export const GetPermitByIdDocument = gql`
    query getPermitById($id: String!) {
  getPermitById(id: $id) {
    _id
    imageUrl
    imageType
    weaponType
    weaponNo
    manufacturer
    model
    caliber
    storageAddress
    validFrom
    validTo
    journalNo
    weaponPart
    images
    owner {
      _id
    }
    user {
      _id
    }
  }
}
    `;

/**
 * __useGetPermitByIdQuery__
 *
 * To run a query within a React component, call `useGetPermitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermitByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPermitByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPermitByIdQuery, GetPermitByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermitByIdQuery, GetPermitByIdQueryVariables>(GetPermitByIdDocument, options);
      }
export function useGetPermitByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermitByIdQuery, GetPermitByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermitByIdQuery, GetPermitByIdQueryVariables>(GetPermitByIdDocument, options);
        }
export type GetPermitByIdQueryHookResult = ReturnType<typeof useGetPermitByIdQuery>;
export type GetPermitByIdLazyQueryHookResult = ReturnType<typeof useGetPermitByIdLazyQuery>;
export type GetPermitByIdQueryResult = Apollo.QueryResult<GetPermitByIdQuery, GetPermitByIdQueryVariables>;
export const EditAndUpdateSubPermitDocument = gql`
    mutation editAndUpdateSubPermit($input: EditSubPermitInput!) {
  editAndUpdateSubPermit(input: $input) {
    _id
  }
}
    `;
export type EditAndUpdateSubPermitMutationFn = Apollo.MutationFunction<EditAndUpdateSubPermitMutation, EditAndUpdateSubPermitMutationVariables>;

/**
 * __useEditAndUpdateSubPermitMutation__
 *
 * To run a mutation, you first call `useEditAndUpdateSubPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAndUpdateSubPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAndUpdateSubPermitMutation, { data, loading, error }] = useEditAndUpdateSubPermitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAndUpdateSubPermitMutation(baseOptions?: Apollo.MutationHookOptions<EditAndUpdateSubPermitMutation, EditAndUpdateSubPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAndUpdateSubPermitMutation, EditAndUpdateSubPermitMutationVariables>(EditAndUpdateSubPermitDocument, options);
      }
export type EditAndUpdateSubPermitMutationHookResult = ReturnType<typeof useEditAndUpdateSubPermitMutation>;
export type EditAndUpdateSubPermitMutationResult = Apollo.MutationResult<EditAndUpdateSubPermitMutation>;
export type EditAndUpdateSubPermitMutationOptions = Apollo.BaseMutationOptions<EditAndUpdateSubPermitMutation, EditAndUpdateSubPermitMutationVariables>;
export const EndLogDocument = gql`
    mutation endLog($input: EndLogInput!) {
  endLog(input: $input) {
    _id
  }
}
    `;
export type EndLogMutationFn = Apollo.MutationFunction<EndLogMutation, EndLogMutationVariables>;

/**
 * __useEndLogMutation__
 *
 * To run a mutation, you first call `useEndLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endLogMutation, { data, loading, error }] = useEndLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndLogMutation(baseOptions?: Apollo.MutationHookOptions<EndLogMutation, EndLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndLogMutation, EndLogMutationVariables>(EndLogDocument, options);
      }
export type EndLogMutationHookResult = ReturnType<typeof useEndLogMutation>;
export type EndLogMutationResult = Apollo.MutationResult<EndLogMutation>;
export type EndLogMutationOptions = Apollo.BaseMutationOptions<EndLogMutation, EndLogMutationVariables>;
export const CreateSubLogDocument = gql`
    mutation createSubLog($input: CreateSubLogInput!) {
  createSubLog(input: $input) {
    _id
  }
}
    `;
export type CreateSubLogMutationFn = Apollo.MutationFunction<CreateSubLogMutation, CreateSubLogMutationVariables>;

/**
 * __useCreateSubLogMutation__
 *
 * To run a mutation, you first call `useCreateSubLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubLogMutation, { data, loading, error }] = useCreateSubLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubLogMutation, CreateSubLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubLogMutation, CreateSubLogMutationVariables>(CreateSubLogDocument, options);
      }
export type CreateSubLogMutationHookResult = ReturnType<typeof useCreateSubLogMutation>;
export type CreateSubLogMutationResult = Apollo.MutationResult<CreateSubLogMutation>;
export type CreateSubLogMutationOptions = Apollo.BaseMutationOptions<CreateSubLogMutation, CreateSubLogMutationVariables>;
export const GetMyLogsPrintDocument = gql`
    query getMyLogsPrint($input: GetMyLogsInput!) {
  getMyLogsV2(input: $input) {
    ...PrintLog
  }
}
    ${PrintLogFragmentDoc}`;

/**
 * __useGetMyLogsPrintQuery__
 *
 * To run a query within a React component, call `useGetMyLogsPrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLogsPrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLogsPrintQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyLogsPrintQuery(baseOptions: Apollo.QueryHookOptions<GetMyLogsPrintQuery, GetMyLogsPrintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyLogsPrintQuery, GetMyLogsPrintQueryVariables>(GetMyLogsPrintDocument, options);
      }
export function useGetMyLogsPrintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyLogsPrintQuery, GetMyLogsPrintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyLogsPrintQuery, GetMyLogsPrintQueryVariables>(GetMyLogsPrintDocument, options);
        }
export type GetMyLogsPrintQueryHookResult = ReturnType<typeof useGetMyLogsPrintQuery>;
export type GetMyLogsPrintLazyQueryHookResult = ReturnType<typeof useGetMyLogsPrintLazyQuery>;
export type GetMyLogsPrintQueryResult = Apollo.QueryResult<GetMyLogsPrintQuery, GetMyLogsPrintQueryVariables>;
export const GetLogDetailsDocument = gql`
    query getLogDetails($id: String!) {
  getLogById(id: $id) {
    ...LogDetails
  }
}
    ${LogDetailsFragmentDoc}`;

/**
 * __useGetLogDetailsQuery__
 *
 * To run a query within a React component, call `useGetLogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLogDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetLogDetailsQuery, GetLogDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogDetailsQuery, GetLogDetailsQueryVariables>(GetLogDetailsDocument, options);
      }
export function useGetLogDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogDetailsQuery, GetLogDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogDetailsQuery, GetLogDetailsQueryVariables>(GetLogDetailsDocument, options);
        }
export type GetLogDetailsQueryHookResult = ReturnType<typeof useGetLogDetailsQuery>;
export type GetLogDetailsLazyQueryHookResult = ReturnType<typeof useGetLogDetailsLazyQuery>;
export type GetLogDetailsQueryResult = Apollo.QueryResult<GetLogDetailsQuery, GetLogDetailsQueryVariables>;
export const DeletePermitDocument = gql`
    mutation deletePermit($permitId: String!) {
  deletePermit(permitId: $permitId) {
    isDeleted
  }
}
    `;
export type DeletePermitMutationFn = Apollo.MutationFunction<DeletePermitMutation, DeletePermitMutationVariables>;

/**
 * __useDeletePermitMutation__
 *
 * To run a mutation, you first call `useDeletePermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermitMutation, { data, loading, error }] = useDeletePermitMutation({
 *   variables: {
 *      permitId: // value for 'permitId'
 *   },
 * });
 */
export function useDeletePermitMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermitMutation, DeletePermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePermitMutation, DeletePermitMutationVariables>(DeletePermitDocument, options);
      }
export type DeletePermitMutationHookResult = ReturnType<typeof useDeletePermitMutation>;
export type DeletePermitMutationResult = Apollo.MutationResult<DeletePermitMutation>;
export type DeletePermitMutationOptions = Apollo.BaseMutationOptions<DeletePermitMutation, DeletePermitMutationVariables>;
export const GetPermitDetailsDocument = gql`
    query getPermitDetails($id: String!) {
  getPermitById(id: $id) {
    ...PermitDetails
  }
}
    ${PermitDetailsFragmentDoc}`;

/**
 * __useGetPermitDetailsQuery__
 *
 * To run a query within a React component, call `useGetPermitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermitDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPermitDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPermitDetailsQuery, GetPermitDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermitDetailsQuery, GetPermitDetailsQueryVariables>(GetPermitDetailsDocument, options);
      }
export function useGetPermitDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermitDetailsQuery, GetPermitDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermitDetailsQuery, GetPermitDetailsQueryVariables>(GetPermitDetailsDocument, options);
        }
export type GetPermitDetailsQueryHookResult = ReturnType<typeof useGetPermitDetailsQuery>;
export type GetPermitDetailsLazyQueryHookResult = ReturnType<typeof useGetPermitDetailsLazyQuery>;
export type GetPermitDetailsQueryResult = Apollo.QueryResult<GetPermitDetailsQuery, GetPermitDetailsQueryVariables>;
export const GetSignedUrlForGetImageDocument = gql`
    query getSignedUrlForGetImage($input: GetImageInput!) {
  getImageUrl(input: $input) {
    url
  }
}
    `;

/**
 * __useGetSignedUrlForGetImageQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlForGetImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlForGetImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlForGetImageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedUrlForGetImageQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUrlForGetImageQuery, GetSignedUrlForGetImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedUrlForGetImageQuery, GetSignedUrlForGetImageQueryVariables>(GetSignedUrlForGetImageDocument, options);
      }
export function useGetSignedUrlForGetImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUrlForGetImageQuery, GetSignedUrlForGetImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedUrlForGetImageQuery, GetSignedUrlForGetImageQueryVariables>(GetSignedUrlForGetImageDocument, options);
        }
export type GetSignedUrlForGetImageQueryHookResult = ReturnType<typeof useGetSignedUrlForGetImageQuery>;
export type GetSignedUrlForGetImageLazyQueryHookResult = ReturnType<typeof useGetSignedUrlForGetImageLazyQuery>;
export type GetSignedUrlForGetImageQueryResult = Apollo.QueryResult<GetSignedUrlForGetImageQuery, GetSignedUrlForGetImageQueryVariables>;
export const ChangeForgotPasswordDocument = gql`
    mutation changeForgotPassword($input: ChangeForgotPasswordInput!) {
  changeForgotPassword(input: $input) {
    _id
  }
}
    `;
export type ChangeForgotPasswordMutationFn = Apollo.MutationFunction<ChangeForgotPasswordMutation, ChangeForgotPasswordMutationVariables>;

/**
 * __useChangeForgotPasswordMutation__
 *
 * To run a mutation, you first call `useChangeForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeForgotPasswordMutation, { data, loading, error }] = useChangeForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeForgotPasswordMutation, ChangeForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeForgotPasswordMutation, ChangeForgotPasswordMutationVariables>(ChangeForgotPasswordDocument, options);
      }
export type ChangeForgotPasswordMutationHookResult = ReturnType<typeof useChangeForgotPasswordMutation>;
export type ChangeForgotPasswordMutationResult = Apollo.MutationResult<ChangeForgotPasswordMutation>;
export type ChangeForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeForgotPasswordMutation, ChangeForgotPasswordMutationVariables>;
export const ConfirmUserDocument = gql`
    query confirmUser($token: String!) {
  confirmUser(token: $token) {
    fullName
    userType
  }
}
    `;

/**
 * __useConfirmUserQuery__
 *
 * To run a query within a React component, call `useConfirmUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmUserQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUserQuery(baseOptions: Apollo.QueryHookOptions<ConfirmUserQuery, ConfirmUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmUserQuery, ConfirmUserQueryVariables>(ConfirmUserDocument, options);
      }
export function useConfirmUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmUserQuery, ConfirmUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmUserQuery, ConfirmUserQueryVariables>(ConfirmUserDocument, options);
        }
export type ConfirmUserQueryHookResult = ReturnType<typeof useConfirmUserQuery>;
export type ConfirmUserLazyQueryHookResult = ReturnType<typeof useConfirmUserLazyQuery>;
export type ConfirmUserQueryResult = Apollo.QueryResult<ConfirmUserQuery, ConfirmUserQueryVariables>;
export const ForgotPasswordDocument = gql`
    query forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions: Apollo.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
      }
export function useForgotPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordQueryResult = Apollo.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;
export const GetPermitsDocument = gql`
    query getPermits {
  getPermits {
    ...PermitRow
  }
}
    ${PermitRowFragmentDoc}`;

/**
 * __useGetPermitsQuery__
 *
 * To run a query within a React component, call `useGetPermitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermitsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermitsQuery, GetPermitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermitsQuery, GetPermitsQueryVariables>(GetPermitsDocument, options);
      }
export function useGetPermitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermitsQuery, GetPermitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermitsQuery, GetPermitsQueryVariables>(GetPermitsDocument, options);
        }
export type GetPermitsQueryHookResult = ReturnType<typeof useGetPermitsQuery>;
export type GetPermitsLazyQueryHookResult = ReturnType<typeof useGetPermitsLazyQuery>;
export type GetPermitsQueryResult = Apollo.QueryResult<GetPermitsQuery, GetPermitsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    _id
    firstName
    lastName
    email
    fullName
    userType
    admin
    confirmed
    address
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateAddressDocument = gql`
    mutation updateAddress($input: UpdateUserInput!) {
  updateUser(input: $input) {
    _id
    address
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const ChangeMailDocument = gql`
    mutation changeMail($email: String!) {
  changeMail(email: $email) {
    _id
    email
  }
}
    `;
export type ChangeMailMutationFn = Apollo.MutationFunction<ChangeMailMutation, ChangeMailMutationVariables>;

/**
 * __useChangeMailMutation__
 *
 * To run a mutation, you first call `useChangeMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMailMutation, { data, loading, error }] = useChangeMailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangeMailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeMailMutation, ChangeMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeMailMutation, ChangeMailMutationVariables>(ChangeMailDocument, options);
      }
export type ChangeMailMutationHookResult = ReturnType<typeof useChangeMailMutation>;
export type ChangeMailMutationResult = Apollo.MutationResult<ChangeMailMutation>;
export type ChangeMailMutationOptions = Apollo.BaseMutationOptions<ChangeMailMutation, ChangeMailMutationVariables>;
export const ConfirmChangeMailDocument = gql`
    query confirmChangeMail($token: String!) {
  confirmChangeMail(token: $token) {
    _id
    email
  }
}
    `;

/**
 * __useConfirmChangeMailQuery__
 *
 * To run a query within a React component, call `useConfirmChangeMailQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmChangeMailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmChangeMailQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmChangeMailQuery(baseOptions: Apollo.QueryHookOptions<ConfirmChangeMailQuery, ConfirmChangeMailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmChangeMailQuery, ConfirmChangeMailQueryVariables>(ConfirmChangeMailDocument, options);
      }
export function useConfirmChangeMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmChangeMailQuery, ConfirmChangeMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmChangeMailQuery, ConfirmChangeMailQueryVariables>(ConfirmChangeMailDocument, options);
        }
export type ConfirmChangeMailQueryHookResult = ReturnType<typeof useConfirmChangeMailQuery>;
export type ConfirmChangeMailLazyQueryHookResult = ReturnType<typeof useConfirmChangeMailLazyQuery>;
export type ConfirmChangeMailQueryResult = Apollo.QueryResult<ConfirmChangeMailQuery, ConfirmChangeMailQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    _id
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const GetMyLogsV2Document = gql`
    query getMyLogsV2($input: GetMyLogsInput!) {
  getMyLogsV2(input: $input) {
    ...LogRow
  }
}
    ${LogRowFragmentDoc}`;

/**
 * __useGetMyLogsV2Query__
 *
 * To run a query within a React component, call `useGetMyLogsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLogsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLogsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyLogsV2Query(baseOptions: Apollo.QueryHookOptions<GetMyLogsV2Query, GetMyLogsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyLogsV2Query, GetMyLogsV2QueryVariables>(GetMyLogsV2Document, options);
      }
export function useGetMyLogsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyLogsV2Query, GetMyLogsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyLogsV2Query, GetMyLogsV2QueryVariables>(GetMyLogsV2Document, options);
        }
export type GetMyLogsV2QueryHookResult = ReturnType<typeof useGetMyLogsV2Query>;
export type GetMyLogsV2LazyQueryHookResult = ReturnType<typeof useGetMyLogsV2LazyQuery>;
export type GetMyLogsV2QueryResult = Apollo.QueryResult<GetMyLogsV2Query, GetMyLogsV2QueryVariables>;