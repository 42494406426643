import { FC } from "react";
import { Box, Container, Typography } from "@mui/material";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangeEmail from "./ChangeEmail/ChangeEmail";
import Address from "./Address/Address";

const Settings: FC = () => (
  <Container maxWidth="sm">
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Typography variant="h5">Bruger indstillinger</Typography>
      <Address />
      <ChangePassword />
      <ChangeEmail />
    </Box>
  </Container>
);

export default Settings;
