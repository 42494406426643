import { Button, CircularProgress } from "@mui/material";
import { FC } from "react";

type Props = {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  label: string;
};

const LoadingButton: FC<Props> = ({ onClick, disabled, loading, label }) => (
  <Button onClick={onClick} variant="contained" disabled={disabled}>
    {loading ? <CircularProgress color={"info"} size={20} /> : label}
  </Button>
);

export default LoadingButton;
