import { Box, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import AlertFooter from "../../../components/AlertFooter/AlertFooter";
import { useMeQuery, useUpdateAddressMutation } from "../../../apollo/types";

const Address: FC = () => {
  const [address, setAddress] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { data: me, loading } = useMeQuery({
    onCompleted: (data) => {
      if (data?.me) {
        setAddress(data.me.address || "");
      }
    },
  });
  const [updateAddress, { loading: updateLoading }] = useUpdateAddressMutation({
    onCompleted: (data) => {
      if (data) {
        setSuccessMessage("Din addresse er blevet opdateret.");
      }
    },
    onError: () => {
      setErrorMessage("Vi kunne ikke opdatere din addresse.");
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Typography>Opdater addresse</Typography>
      <TextField
        disabled={loading || updateLoading}
        label="Addresse"
        variant="outlined"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
      />
      <AlertFooter
        button={{
          label: "Opdater addresse",
          onClick: () => updateAddress({ variables: { input: { address } } }),
          disabled: loading || updateLoading || !address,
        }}
        loading={loading || updateLoading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onCloseSuccessAlert={() => setSuccessMessage("")}
        onCloseErrorAlert={() => setErrorMessage("")}
      />
    </Box>
  );
};

export default Address;
