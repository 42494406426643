import { Box, Container, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyLogType, useRegisterMutation } from "../../apollo/types";
import AlertFooter from "../../components/AlertFooter/AlertFooter";
import { TextButton } from "../../components/modals/AuthModal/AuthModal.styles";
import PasswordField from "../../components/PasswordField/PasswordField";
import { GET_PERMITS } from "../permits/getPermits";
import { ME_QUERY } from "../root/me";
import RegisterSuccess from "./RegisterSuccess";
import { GET_MY_LOGS_V2 } from "../weaponLogs/queries/getMyLogsV2";

const Register: FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const navigate = useNavigate();

  const [register, { data: registerData, loading }] = useRegisterMutation({
    onError: (error) => setErrorMessage(error.message),
    onCompleted: () => setRegisterSuccess(true),
  });

  const handleOnClick = async () => {
    if (password !== repeatPassword) {
      setErrorMessage("Passwords er ikke ens");
      return;
    }
    const options = {
      variables: {
        input: {
          email,
          firstName,
          lastName,
          password,
        },
      },
      // TODO: Check if this is needed
      refetchQueries: [
        { query: ME_QUERY },
        { query: GET_PERMITS },
        {
          query: GET_MY_LOGS_V2,
          variables: {
            input: {
              logType: MyLogType.Active,
            },
          },
        },
      ],
    };
    await register(options);
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h5">Opret Bruger</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {registerSuccess ? (
            <RegisterSuccess
              email={registerData?.register.email || ""}
              fullName={registerData?.register.fullName || ""}
            />
          ) : (
            <>
              <TextField
                label="For navn(e)"
                autoFocus
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
              <TextField
                label="Efternavn"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <PasswordField
                label="Password"
                id="input-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <PasswordField
                id="input-repeat-password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                label="Gentag password"
              />
              <Typography textAlign="center">
                <TextButton onClick={() => navigate("/login")}>
                  Gå til login
                </TextButton>
              </Typography>
              <AlertFooter
                button={{
                  label: "Opret bruger",
                  onClick: handleOnClick,
                  disabled:
                    !firstName ||
                    !lastName ||
                    !email ||
                    !password ||
                    !repeatPassword ||
                    loading,
                }}
                loading={loading}
                successMessage={successMessage}
                errorMessage={errorMessage}
                onCloseSuccessAlert={() => setSuccessMessage("")}
                onCloseErrorAlert={() => setErrorMessage("")}
              />
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
