import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useChangePasswordMutation } from "../../../apollo/types";
import ForgotPasswordLink from "../../../components/ForgotPasswordLink/ForgotPasswordLink";
import AlertFooter from "../../../components/AlertFooter/AlertFooter";
import PasswordField from "../../../components/PasswordField/PasswordField";

const ChangePassword: FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const resetState = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatNewPassword("");
    setSuccessMessage("");
    setErrorMessage("");
  };

  const [changePassword, { loading }] = useChangePasswordMutation({
    onCompleted: (data) => {
      if (data.changePassword?._id) {
        resetState();
        setSuccessMessage("Password er skiftet");
      } else {
        setErrorMessage("Vi kunne ikke skifte dit password.");
      }
    },
    onError: () => {
      resetState();
      setErrorMessage("Vi kunne ikke skifte dit password..");
    },
  });

  const handleOnClick = () =>
    changePassword({ variables: { currentPassword, newPassword } });
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Typography>Skift password</Typography>
      <PasswordField
        id="input-current-password"
        label="Nuværende password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <ForgotPasswordLink />
      <PasswordField
        id="input-new-password"
        label="Nyt password (min 8 tegn)"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <PasswordField
        id="input-repeat-password"
        label="Gentag nyt password"
        value={repeatNewPassword}
        onChange={(e) => setRepeatNewPassword(e.target.value)}
      />
      <AlertFooter
        button={{
          label: "Skift password",
          onClick: handleOnClick,
          disabled:
            !currentPassword ||
            !newPassword ||
            !repeatNewPassword ||
            newPassword !== repeatNewPassword ||
            loading ||
            newPassword.length < 8,
        }}
        loading={loading}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onCloseErrorAlert={() => setErrorMessage("")}
        onCloseSuccessAlert={() => setSuccessMessage("")}
      />
    </Box>
  );
};

export default ChangePassword;
