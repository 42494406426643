import { FC, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useMeQuery } from "../../apollo/types";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import TopBar from "../../components/TopBar/TopBar";

const Root: FC = () => {
  const { data, loading } = useMeQuery();
  const location = useLocation();
  const { token } = useParams();

  const authExcludedPaths = [
    `/confirm/${token}`,
    `/change-password/${token}`,
    "/forgot-password",
    "/login",
    "/register",
  ];

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="root">
      {!!data?.me && <TopBar />}
      {authExcludedPaths.includes(location.pathname) || !!data?.me ? (
        <div style={{ paddingTop: 30 }}>
          <Outlet />
        </div>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </div>
  );
};

export default Root;
