import { Alert, Box } from "@mui/material";
import { FC } from "react";
import LoadingButton from "../LoadingButton/LoadingButton";

type Props = {
  button: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
  loading?: boolean;
  successMessage?: string;
  errorMessage?: string;
  onCloseSuccessAlert?: () => void;
  onCloseErrorAlert?: () => void;
};

const AlertFooter: FC<Props> = ({
  button,
  loading,
  successMessage,
  errorMessage,
  onCloseErrorAlert,
  onCloseSuccessAlert,
}) => {
  const { label, onClick, disabled } = button;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {!!successMessage && (
        <Alert
          sx={{ zIndex: 10 }}
          severity="success"
          onClose={onCloseSuccessAlert}
        >
          {successMessage}
        </Alert>
      )}
      {!!errorMessage && (
        <Alert sx={{ zIndex: 10 }} severity="error" onClose={onCloseErrorAlert}>
          {errorMessage}
        </Alert>
      )}
      <Box>
        <LoadingButton
          onClick={onClick}
          disabled={disabled}
          loading={loading}
          label={label}
        />
      </Box>
    </Box>
  );
};

export default AlertFooter;
